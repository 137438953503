import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import key_by from 'lodash.keyby'

import PlaceSelect from 'src/components/common/place-select'
import DatePicker from 'src/components/common/date-picker'
import TravelerSelect from 'src/components/common/traveler-select'
import SearchBtn from '../search-btn'

import { get_field_settings, PACKAGE_BUNDLE_IDS } from 'src/composables/utils/forms'
import CabinSelect from 'src/components/common/cabin-select/index.vue'
import SeparateCheckInput from 'src/components/common/package-form/bundle/separate-check-input/index.vue'

export const get_meta_default = (params) => {
  const { t } = useI18n()
  const { props, rules, traveler_limits, date_format, payload ,validate_airport } = params
  const setting_default = props.setting?.meta?.default?.fields ?? {}
  const field_setting = key_by(setting_default, 'id') ?? {}

  return computed(() => ({
    fields: [
      {
        id: PACKAGE_BUNDLE_IDS.DP_AIRPORT,
        component: PlaceSelect,
        hidden: false,
        model: 'dp_airport',
        order: 1,
        events: {},
        bind: {
          city_code: true,
          cities_with_airports: true,
          dense: true,
          types: props.place_types,
          rules: rules.dp_airport,
          outlined: true,
          'hide-bottom-space': true,
          'fill-input': true,
          'hide-selected': true,
          static_route_enabled: props.product.static_route_enabled,
          destination_type: props.product.static_route_enabled ? 'departure' : '',
          prepend_icon: 'flight_takeoff',
          arrival_code:
            props.product.static_route_enabled && payload.value.ar_airport
              ? payload.value.ar_airport.code
              : '',
          label: t('common.origin'),
          has_code: true,
          validate: (item) => validate_airport(item, 'dp_airport')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.DP_AIRPORT,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 1,
              tablet: 1,
              mobile: 1
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_BUNDLE_IDS.AR_AIRPORT,
        component: PlaceSelect,
        hidden: false,
        model: 'ar_airport',
        order: 2,
        events: {},
        bind: {
          city_code: true,
          cities_with_airports: true,
          dense: true,
          types: props.place_types,
          rules: rules.ar_airport,
          outlined: true,
          'hide-bottom-space': true,
          'fill-input': true,
          'hide-selected': true,
          static_route_enabled: props.product.static_route_enabled,
          destination_type: props.product.static_route_enabled ? 'arrival' : '',
          prepend_icon: 'place',
          arrival_code:
            props.product.static_route_enabled && payload.value.dp_airport
              ? payload.value.dp_airport.code
              : '',
          label: t('common.destination'),
          has_code: true,
          validate: (item) => validate_airport(item, 'ar_airport')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.AR_AIRPORT,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 2,
              tablet: 2,
              mobile: 2
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_BUNDLE_IDS.FLIGHT_DATES,
        component: DatePicker,
        hidden: false,
        model: 'flight_dates',
        order: 3,
        events: {},
        bind: {
          range: true,
          dense: true,
          outlined: true,
          'hide-bottom-space': true,
          date_format,
          booking_cutoff_days: props.bundle.booking_cutoff_days,
          label: t('common.departure-return'),
          rules: rules.dates,
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.FLIGHT_DATES,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 3,
              tablet: 3,
              mobile: 3
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_BUNDLE_IDS.SEARCH_BTN,
        component: SearchBtn,
        hidden: false,
        model: 'payload',
        order: 4,
        events: {},
        bind: {
          label: t('common.search-now'),
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.SEARCH_BTN,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 4,
              tablet: 4,
              mobile: 10
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_BUNDLE_IDS.TRAVELERS,
        component: TravelerSelect,
        hidden: false,
        model: 'travelers',
        order: 5,
        events: {},
        bind: {
          dense: true,
          rules: rules.travelers,
          outlined: true,
          'hide-bottom-space': true,
          with_rooms: true,
          max_rooms: 3,
          max_adults: +traveler_limits?.['adult']?.limit,
          max_infants: +traveler_limits?.['infant']?.limit,
          max_children: +traveler_limits?.['child']?.limit,
          traveler_limit_type: props.bundle?.traveler_limit_type,
          total_traveler_limit: props.bundle?.total_traveler_limit,
          traveler_types: props.product.traveler_types,
          default_traveler_count: props.bundle.default_traveler_count,
          label: t('common.travelers')
        },
        style: {
          color: props.color
        },

        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.TRAVELERS,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 5,
              tablet: 5,
              mobile: 5
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_BUNDLE_IDS.CABIN_CLASS,
        component: CabinSelect,
        hidden: false,
        model: 'cabin_class',
        order: 6,
        events: {},
        bind: {
          dense: true,
          rules: rules.cabin_class,
          outlined: true,
          'hide-bottom-space': true,
          cabin_classes: props.product.cabin_classes,
          'emit-value': true,
          'map-options': true,
          prepend_icon: 'class',
          label: t('common.cabin-class')
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.CABIN_CLASS,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 6,
              tablet: 6,
              mobile: 6
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_BUNDLE_IDS.HOTEL_DATES,
        component: SeparateCheckInput,
        hidden: false,
        model: 'form_data',
        model_path: 'separate',
        order: 7,
        events: {},
        bind: {
          dense: true,
          flight_dates: payload.value.flight_dates,
          date_format,
          hotel_dates: payload.value.hotel_dates
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_BUNDLE_IDS.HOTEL_DATES,
          settings: field_setting,
          default_values: {
            order: {
              desktop: 7,
              tablet: 7,
              mobile: 7
            },
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      }
    ]
  }))
}
