<template>
  <q-icon v-if="!isSvg" :name="name" />
  <span
    :style="{ width: `${size_px}px`, height: `${size_px}px` }"
    v-else
    v-html="processedSvg"
  />
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  name: { type: String, required: true },
  fill: { type: String, required: false },
  size_px: { type: Number, default: 18 }
})

const isSvg = computed(() => props.name.startsWith('<svg '))

const processedSvg = computed(() => {
  if (isSvg.value && props.fill) {
    return props.name.replace(/fill="[^"]*"/g, `fill="${props.fill}"`)
  }
  return props.name
})
</script>
