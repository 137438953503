<template>
  <q-list
    :class="separator ? 'egs-place-select-list' : 'egs-mb-place-select-list'"
    :separator="group_by_countries ? false : separator">
    <template v-if="options.length">
      <template v-for="(option, index) in options" :key="option.id">
        <item v-intersect="e => handle_intersect(e, (options.length - index) < 5)"
              :group_by_countries="group_by_countries"
              :is_frist_item="index === 0"
              :mode-value="modelValue"
              :option="option"
              :search_text="search_text"
              class="q-py-md"
              @select="handle_select"
        />
      </template>
    </template>

  </q-list>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import Item from './item'

const props = defineProps({
  modelValue: { type: [Object, Array, String] },
  options: { type: Array, default: [] },
  separator: { type: Boolean, default: false },
  fetching: { type: Boolean, default: false },
  search_text: { type: String, default: '' },
  group_by_countries: { type: Boolean, default: false }
})

const emit = defineEmits(['select', 'loadmore'])
const handle_select = (option) => {
  emit('select', option)
}
const handle_intersect = (isShow, can_loadmore) => {
  if (can_loadmore && isShow && !props.fetching) {
    emit('loadmore')
  }
}

</script>
