<template>
  <div v-bind="bind">
    <q-select ref="el" v-model="context_store.language" :options="context_store.languages" :style="{ '--q-primary': meta?.color ?? 'black' }"
              class="egs-select"
              v-bind="select_bind">
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps">
          <q-item-section>
            <q-item-label>{{ scope.opt.code }}</q-item-label>
            <q-item-label caption>{{ scope.opt.name }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>
<script setup>

import { DEFAULT_INPUT_DESIGN, NODE_BASE_PROPS } from 'src/constants'
import { computed, defineProps, ref } from 'vue'
import { generate_common_node_styles } from 'src/composables/canvas'
import { stores } from 'src/stores'

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const props = defineProps(NODE_BASE_PROPS)
const meta = computed(() => props.node.meta)

const styles = computed(() => {
  let result = generate_common_node_styles(props.node)

  if (!props.parent.layout.columns.enable) {
    const width = props.node.dimension.width
    if (width) result['width'] = `${width}px`
  }

  return result
})

const el = ref()

const bind = computed(() => {
  let result = {
    'style': styles.value
  }

  return result
})

const select_bind = computed(() => {
  let result = {
    dense: meta.value?.dense,
    'option-label': 'code',
    'option-value': 'code',
    'label': meta.value?.label[context_store.language.code]
  }

  if (meta.value.design !== DEFAULT_INPUT_DESIGN) {
    result[meta.value.design] = true
  }

  return result
})

</script>
